@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200');

body  {
    background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
  background-size:cover;
        -webkit-animation: slidein 100s;
        animation: slidein 100s;

        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;

        -webkit-animation-direction: alternate;
        animation-direction: alternate;              
}

@-webkit-keyframes slidein {
from {background-position: top; background-size:3000px; }
to {background-position: -100px 0px;background-size:2750px;}
}

@keyframes slidein {
from {background-position: top;background-size:3000px; }
to {background-position: -100px 0px;background-size:2750px;}

}


/* App Div */
.AppDiv{
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100vh;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(75, 75, 250, 0.2);
  /* background-color: rgb(242, 240, 248); */
  /* background-color: #31034d; */
  /* background: linear-gradient(to right, #31034d 50%, #ffff 50%); */
  /* background-color: #C8E7FF; */
  /* background-color: #fffdfe; */
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  /* font-family: 'Source Code Pro', monospace; */

   /* text-align:center; */
   /* color:white; */
   font-family: 'Source Code Pro', monospace;
   /* font-family: 'Courier New', Courier, monospace; */
   /* text-transform:uppercase; */
   font-weight: bold;
}

/* Navbar */
.navbar {
  list-style-type: none;
  padding: 10px;
  margin: 0;
  display: flex;
  /* background-color: rgb(41, 145, 249); */
  /* background-color: #DEAAFF; */
  /* background-color: #fae1fefa; */
  /* background-color: #31034d; */
    /* background-color: rgba(240, 172, 251, 0.2); */
    background-color: rgba(250, 225, 254, 0.5);
  color: #31034d;
  display: flex;
  align-items: center;
  justify-content:space-around;
  flex-wrap: wrap;
  width: 100vw;
}

.navbar li {
  margin-right: 20px; /* Adjust as needed */
  flex-grow: 1; /* Allow items to expand to fill available space */
  min-width: 0; /* Ensure content can wrap */
}

@media screen and (max-width: 600px) {
  .navbar {
    flex-direction: column;
  }
}

/* About Me */
.aboutMe-container{
  width: 50%;
  margin: 10%;
  padding: 3%;
  /* background-color: rgb(203, 227, 244); */
  /* background-color: #fae1fefa; */
  /* background-color: rgba(240, 172, 251, 0.3); */
  /* color: #31034d; */
  /* border: 5px solid #fae1fefa; */
  color:azure;
  border-radius: 15px;
}

.text-3d {
  /* font-family: verdana; */
   /* font-family: 'Courier New', Courier, monospace; */
   /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-size: 13em;
  font-weight: bold;
  /* color: #31034d; */
  text-shadow: 
    1px 1px 1px #919191,
    1px 2px 1px #919191,
    1px 3px 1px #919191,
    1px 4px 1px #919191,
    1px 5px 1px #919191,
    1px 6px 1px #919191,
    1px 7px 1px #919191,
    1px 8px 1px #919191,
    1px 9px 1px #919191,
    1px 10px 1px #919191,
    /* 1px 18px 6px rgba(16, 16, 16, 0.4), */
    /* 1px 22px 10px rgba(16, 16, 16, 0.2), */
    1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}


/* Navbar */
/* .container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  background-color: #fef7e5;
  position: relative;
}

.logo {
}

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
} */
